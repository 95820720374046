import { getFavorites } from 'actions/favorites';
import { getMeals } from 'actions/meals';
import { getUser } from 'actions/user';

export const BOOTSTRAP_COOKBOOK = 'BOOTSTRAP_COOKBOOK';
export const BOOTSTRAP_COOKBOOK_SUCCESS = 'BOOTSTRAP_COOKBOOK_SUCCESS';
export const BOOTSTRAP_COOKBOOK_ERROR = 'BOOTSTRAP_COOKBOOK_ERROR';

export const bootstrapCookbook =
  ({ searchParams }) =>
  async dispatch => {
    dispatch({ type: BOOTSTRAP_COOKBOOK });
    try {
      await dispatch(getMeals(searchParams));
      await dispatch(getUser());
      await dispatch(getFavorites());
      dispatch({ type: BOOTSTRAP_COOKBOOK_SUCCESS });
    } catch {
      dispatch({ type: BOOTSTRAP_COOKBOOK_ERROR });
    }
  };
