import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import { ACCEPT, DISMISS } from 'actions/dismissals';
import { LOCAL_STORAGE_INITIALIZE, loadFromStorage } from 'actions/localStorage';
import { migrateDismissals } from 'helpers/dismissals';
import LocalStorage from 'helpers/localStorage';
import * as fromDismissals from 'reducers/dismissals';
import * as fromShoppingList from 'reducers/shoppingList';

export const STORAGE_KEY = 'reduxState';

export default ({ dispatch, getState }) =>
  next => {
    if (!LocalStorage.isAvailable()) return next;

    /**
     * Handle changes to state shape and how it's stored
     */
    const runMigrations = () => {
      try {
        const migratedData = migrateDismissals();
        if (!isEmpty(migratedData)) {
          LocalStorage.write(STORAGE_KEY, { dismissals: migratedData });
        }
      } catch {
        return;
      }
    };

    /**
     * Pull from localStorage and dispatch a LOCAL_STORAGE_LOAD with the data,
     * so reducers can listen to LOCAL_STORAGE_LOAD and load what they want.
     * @returns {undefined}
     */
    const initialize = () => {
      runMigrations();
      const persistedData = LocalStorage.read(STORAGE_KEY);
      if (isPlainObject(persistedData)) {
        dispatch(loadFromStorage(persistedData));
      }
    };

    /**
     * Read the data off state for serialization into localStorage
     * @param {Object} state Redux state
     * @returns {Object}
     */
    const getDataToPersist = () => {
      const state = getState();
      const data = {
        dismissals: fromDismissals.selectDismissals(state),
        shoppingList: fromShoppingList.selectShoppingList(state),
      };
      return omitBy(data, value => isUndefined(value));
    };

    /**
     * Serialize the persistence-worthy slices into localStorage
     * @returns {undefined}
     */
    const persistState = () => {
      const data = getDataToPersist();
      LocalStorage.write(STORAGE_KEY, data);
    };

    /**
     * @param {Object} action the Redux action
     * @returns {Anything} whatever is returned by the rest of the middleware chain
     */
    return action => {
      const previousState = getState();
      const actionResult = next(action);
      const nextState = getState();

      if (action.type === LOCAL_STORAGE_INITIALIZE) {
        initialize();
      } else if (
        action.type === ACCEPT ||
        action.type === DISMISS ||
        fromShoppingList.selectShoppingList(previousState) !==
          fromShoppingList.selectShoppingList(nextState)
      ) {
        persistState();
      }

      return actionResult;
    };
  };
