import {
  // mealServings actions
  ADD_MEAL,
  REMOVE_MEAL,
  ADD_SERVINGS,
  REMOVE_SERVINGS,
  UPDATE_MEAL_SERVINGS,
  SET_BASKET_SERVINGS,
  SET_EDITED_BASKET,
  UNDO_BASKET_EDITS,
  CLEAR_EDITED_BASKET,

  // mealOptions actions
  CHANGE_MEAL_OPTION,
  UNDO_MEAL_OPTIONS,

  // helper functions
  setCleanBasketEditState,
  hasBasketStateChanged,
} from 'actions/basketEdits';

import {
  selectMealServingsForBasketEdits,
  selectMealOptionsForBasketEdits,
} from 'reducers/dashboard';

const middleware = store => next => action => {
  const result = next(action);

  const actionsThatTriggerStateCheck = [
    ADD_MEAL,
    REMOVE_MEAL,
    ADD_SERVINGS,
    REMOVE_SERVINGS,
    UPDATE_MEAL_SERVINGS,
    SET_BASKET_SERVINGS,
    UNDO_BASKET_EDITS,
    CLEAR_EDITED_BASKET,
    CHANGE_MEAL_OPTION,
    UNDO_MEAL_OPTIONS,
  ];

  const state = store.getState();
  const mealServings = selectMealServingsForBasketEdits(state);
  const selectedMealOptions = selectMealOptionsForBasketEdits(state);

  if (actionsThatTriggerStateCheck.includes(action.type)) {
    store.dispatch(hasBasketStateChanged(mealServings, selectedMealOptions));
  } else if (action.type === SET_EDITED_BASKET) {
    store.dispatch(setCleanBasketEditState(mealServings, selectedMealOptions));
  }

  return result;
};

export default middleware;
